import { useContext, useEffect, useState } from "react";
import Btn from "../../Components/Btn/Btn";

import SecondHeader from "../../Components/Header/SecondHeader";
import { Axios } from "../../Api/axios";
import { CheckSeats, Pending, baseURL } from "../../Api/Api";
import "./Chair.css";

import { Button, Form } from "react-bootstrap";
import BackTrip from "./BackTrip";
import { useNavigate } from "react-router-dom";
import CircleLoading from "../../Components/Loading/CircleLoading";
import LoadingSpinner from "../../Components/Loading/LoadingSpinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../Components/Footer/Footer";
import { Ticket } from "../../Context/TicketContext";
import Cookie from "cookie-universal";

export default function Chairs() {
  // States
  const [seats, setSeats] = useState([]);
  const [fleet, setFleet] = useState([]);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [openBack, setOpenBack] = useState(false);
  const [childCount, setChildCount] = useState(0);
  const [adultCount, setAdultCount] = useState(0);
  const [maxCount, setMaxCount] = useState(0);
  const [canChoose, setCanChoose] = useState(true);
  const [trip, setTrip] = useState([0]);

  const [localTrip, setLocalTrips] = useState({});
  const [CircleLoadingIcon, setCircleLoadingIcon] = useState(false);
  const [loading, setLoading] = useState(true);

  // ids
  const [ids, setIds] = useState(0);

  // BackTrip Chairs States
  const [selectedBackSeats, setSelectedBackSeats] = useState([]);
  const [tripId, setTripId] = useState("");
  const [maxBackCount, setBackMaxCount] = useState(0);

  // Context
  const GetTicket = useContext(Ticket);
  // Navigate
  const nav = useNavigate();

  // Cookie
  const cookie = Cookie();

  // Get Data
  useEffect(() => {
    setMaxCount(selectedSeats.length);
    setBackMaxCount(selectedSeats.length);
  }, [selectedSeats]);
  useEffect(() => {
    if (maxCount >= 10) {
      toast.error("لايمكن اختيار اكثر من 10 مقاعد");
      setMaxCount(10);
      setBackMaxCount(10);
      setCanChoose(false);
      if (adultCount === 9) {
        setAdultCount((prev) => prev + 1);
      }
    } else if (maxCount > adultCount) {
      setAdultCount((prev) => prev + 1);
      setCanChoose(true);
    } else {
      if (adultCount > 0) {
        setAdultCount((prev) => prev - 1);

        setCanChoose(true);
      }
      if (childCount > 0) {
        setChildCount((prev) => prev - 1);

        setCanChoose(true);
      }
    }
  }, [maxCount]);

  useEffect(() => {
    const trip = JSON.parse(localStorage.getItem("trip"));
    setIds(localStorage.getItem("goId"));
    let getId = localStorage.getItem("goId");
    setOpenBack(localStorage.getItem("openBack"));
    const tripId = trip.filter((trip) => trip.id === getId)[0].id;
    setTrip(trip);
    const localTrip = JSON.parse(localStorage.getItem("localTrip"));
    setLocalTrips(localTrip);
    const pick_stand = localTrip.pick_location_id;
    const journeydate = localTrip.goDate;

    Axios.get(
      `${baseURL}/${CheckSeats}?pick_stand=${pick_stand}&journeydate=${journeydate}&trip_id=${tripId}`
    )
      .then((response) => {
        const { booked_seats } = response.data;
        const { fleet } = response.data;
        const seats = booked_seats.split(",").map(Number);
        //console.log(response.data);
        setFleet(fleet);
        setSeats(seats);
      })
      .catch((err) => {
        cookie.remove("amawaj-pri");
        nav("/login");
      })
      .finally(() => setLoading(false));
  }, []);

  // Handle Operations

  const handleChange = (event) => {
    const inputValue = parseInt(event.target.value, 10);
    const inputName = event.target.name;

    // Check if the input value is a non-negative number
    if (inputValue >= 0 && inputValue <= maxCount) {
      if (inputName === "child") {
        const newChildCount = inputValue;
        const newAdultCount = maxCount - newChildCount;
        setChildCount(newChildCount);
        setAdultCount(newAdultCount);
      } else if (inputName === "adult") {
        const newAdultCount = inputValue;
        const newChildCount = maxCount - newAdultCount;
        setAdultCount(newAdultCount);
        setChildCount(newChildCount);
      }
    }
  };

  // Handle Send Trip
  async function HandleSendTrip() {
    setCircleLoadingIcon(true);
    const formData = new FormData();
    formData.append(
      "isround",
      openBack === "true" ? 1 : localTrip.backDate === "no back date" ? 0 : 2
    );
    formData.append("aseat", adultCount);
    formData.append("cseat", childCount);
    formData.append("trip_id_go", trip.filter((trip) => trip.id === ids)[0].id);
    formData.append("journeydate_go", localTrip.goDate);
    formData.append(
      "pickstand_go",
      trip.filter((trip) => trip.id === ids)[0].pick_location_id
    );
    formData.append(
      "dropstand_go",
      trip.filter((trip) => trip.id === ids)[0].drop_location_id
    );
    formData.append("seatnumbers_go", selectedSeats);
    if (localTrip?.backDate !== "no back date") {
      formData.append("journeydate_back", localTrip.backDate);
      formData.append("pickstand_back", localTrip.drop_location_id);
      formData.append("dropstand_back", localTrip.pick_location_id);
      formData.append("trip_id_back", tripId);
      formData.append("seatnumbers_back", selectedBackSeats);
    }
    try {
      const res = await Axios.post(`/${Pending}`, formData, {});
      setCircleLoadingIcon(false);
      if (res.data.data === "passanger_count_error") {
        toast.error("يجب ان يكون عدد كراسي الذهاب نفس عدد كراسي العودة");
      } else if (res.data.message === "تم حجز المقعد بواسطة شخص أخر") {
        toast.error("بعض المقاعد من قبل شخص اخر");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        localStorage.setItem("tickets", res.data.data);
        GetTicket.setTicket({ price: res.data.price });
        nav("/payment");
      }
      //console.log(res);
    } catch (err) {
      cookie.remove("amawaj-pri");
      nav("/login");
    }
  }

  return (
    <>
      <ToastContainer
        containerId="3"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <SecondHeader />
      <div className="bg-url-without d-flex align-items-start gap-5 justify-content-center flex-column p-md-5 p-2">
        {/* Go Tirp */}
        <div
          className={`col-12 d-flex align-items-center  justify-content-${
            loading ? "center" : "end"
          } flex-lg-row flex-column-reverse shadow rounded bg-white-opacity position-relative`}
          style={{ minHeight: "390px" }}
        >
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div className="col-lg-4 col-12 p-3">
                {openBack === "true" && (
                  <h5 className="bg-success text-white p-2 w-fit-content mb-3 mx-auto">
                    العودة مفتوحة
                  </h5>
                )}
                <div
                  dir="rtl"
                  className="col-12 d-flex align-items-center justify-content-between"
                >
                  <h5 className="m-0">سفر من {localTrip?.pick_location}</h5>
                  <img
                    src={require("../../Assets/images/icon/front-of-bus.png")}
                    alt=""
                    width="35px"
                  />
                  <h5 className="m-0"> الى {localTrip?.drop_location}</h5>
                </div>
                <div className="d-flex align-items-center justify-content-center mt-5 gap-5">
                  <div className="text-center">
                    <img
                      width="30px"
                      src={require("../../Assets/images/icon/red.png")}
                      alt=""
                    />
                    <p>مقعد محجوز</p>
                  </div>
                  <div className="text-center">
                    <img
                      width="30px"
                      src={require("../../Assets/images/icon/empty.png")}
                      alt=""
                    />
                    <p>مقعد فارغ</p>
                  </div>
                  <div className="text-center">
                    <img
                      width="30px"
                      src={require("../../Assets/images/icon/green.png")}
                      alt=""
                    />
                    <p>مقعد قمت بحجزه</p>
                  </div>
                </div>
                <h5 dir="rtl">
                  المقاعد المحجوزة : {selectedSeats.map((item) => item + ",")}
                </h5>
                <div className="d-flex align-items-center justify-content-center gap-3">
                  <Form.Group controlId="child" dir="rtl">
                    <Form.Label>عدد الاطفال</Form.Label>
                    <Form.Control
                      value={childCount}
                      onChange={handleChange}
                      type="number"
                      name="child"
                      placeholder="0"
                    />
                  </Form.Group>
                  <Form.Group controlId="parent" dir="rtl">
                    <Form.Label>عدد الكبار</Form.Label>
                    <Form.Control
                      value={adultCount}
                      onChange={handleChange}
                      type="number"
                      name="adult"
                      placeholder="0"
                    />
                  </Form.Group>
                </div>
                <div className="text-end  mt-2" dir="rtl">
                  <p>نفاصيل الاجرة</p>
                  <div className="d-flex align-items-center justify-content-between">
                    <p>مجموع سعر مقاعد الاطفال</p>
                    <p>
                      {trip.filter((trip) => trip.id === ids)[0]?.child_fair *
                        childCount}{" "}
                      ج.م
                    </p>
                  </div>{" "}
                  <div className="d-flex align-items-center justify-content-between">
                    <p>مجموع سعر مقاعد الكبار</p>
                    <p>
                      {trip.filter((trip) => trip.id === ids)[0]?.adult_fair *
                        adultCount}{" "}
                      ج.م
                    </p>
                  </div>{" "}
                  {localTrip?.backDate !== "no back date" ||
                  openBack === "true" ? (
                    <div className="d-flex align-items-center justify-content-between">
                      <p>خصم</p>
                      <p>
                        {localTrip?.backDate !== "no back date" &&
                        openBack === "false"
                          ? trip.filter((trip) => trip.id === ids)[0]
                              ?.adult_discount * adultCount
                          : trip.filter((trip) => trip.id === ids)[0]
                              ?.open_adult_discount * adultCount}
                        ج.م
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="d-flex align-items-center justify-content-between">
                    <p>
                      {localTrip?.backDate !== "no back date"
                        ? "التكلفة النهائية"
                        : "تكلفة رحلة الذهاب"}
                    </p>
                    <p>
                      {openBack === "true"
                        ? trip.filter((trip) => trip.id === ids)[0]
                            ?.adult_fair *
                            adultCount *
                            2 +
                          trip.filter((trip) => trip.id === ids)[0]
                            ?.child_fair *
                            childCount *
                            2 -
                          trip.filter((trip) => trip.id === ids)[0]
                            ?.open_adult_discount *
                            adultCount
                        : localTrip?.backDate !== "no back date" &&
                          openBack === "false"
                        ? trip.filter((trip) => trip.id === ids)[0]
                            ?.adult_fair *
                            adultCount *
                            2 +
                          trip.filter((trip) => trip.id === ids)[0]
                            ?.child_fair *
                            childCount *
                            2 -
                          trip.filter((trip) => trip.id === ids)[0]
                            ?.adult_discount *
                            adultCount
                        : trip.filter((trip) => trip.id === ids)[0]
                            ?.adult_fair *
                            adultCount +
                          trip.filter((trip) => trip.id === ids)[0]
                            ?.child_fair *
                            childCount}
                      ج.م
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column row-gap-2 p-lg-5 p-2 chairs-pos">
                <div className="d-flex justify-content-between align-items-center w-300">
                  <Btn
                    classes="bg-danger text-white m-0 px-5 "
                    title="السائق"
                  />
                  <p className="m-0 rounded px-3 me-3 fs-5 py-1 f-eng  bg-secondary text-white w-fit-content">
                    0
                  </p>
                </div>
                {fleet === 13 ?
                <div
                  className="d-flex flex-wrap w-300 position-relative chairs-height"
                  dir="rtl"
                > 
                  <img
                    src={require("../../Assets/images/icon/toilet.png")}
                    alt=""
                    className="position-absolute chair"
                    style={{ right: "0", top: `${4 * 40}px` }}
                  />
                  <img
                    src={require("../../Assets/images/icon/toilet.png")}
                    alt=""
                    className="position-absolute chair"
                    style={{ right: "45px", top: `${4 * 40}px` }}
                  />
                

                  <img
                    src={require("../../Assets/images/icon/toilet.png")}
                    alt=""
                    className="position-absolute chair"
                    style={{ right: "0", top: `${5 * 40}px` }}
                  />{" "}
                  <img
                    src={require("../../Assets/images/icon/toilet.png")}
                    alt=""
                    className="position-absolute chair"
                    style={{ right: "45px", top: `${5 * 40}px` }}
                  />
                  <img
                    src={require("../../Assets/images/icon/stairs_upper.png")}
                    alt=""
                    dir='ltr'
                    className="position-absolute chair"
                    style={{ right: "0", top: `${6 * 40}px` }}
                  />{" "}
                  <img
                    src={require("../../Assets/images/icon/stairs_upper.png")}
                    alt=""
                    dir='ltr'
                    className="position-absolute chair"
                    style={{ right: "45px", top: `${6 * 40}px`,  }}
                  />
                  {Array.from({ length: 50 }, (_, seatIndex) => {
                    const rowIndex = Math.floor(seatIndex / 3);
                    const colIndex = seatIndex % 3;
                    const isBooked = seats.includes(seatIndex + 1);
                    const isSelected = selectedSeats.includes(seatIndex + 1);
                    const imageIndex = rowIndex * 3 + colIndex;
                    let leftStyle;
                    let rightStyle;
                    let topStyle;

                    if (seatIndex >=0 && seatIndex < 12) {
                      if (colIndex === 1) {
                        leftStyle = "45px";
                      } else if (colIndex === 2) {
                        leftStyle = "0px";
                      } else if (colIndex === 0) {
                        leftStyle = "0px";
                      } 
  
                      if (colIndex === 2 || colIndex === 1) {
                        rightStyle = "";
                      } else {
                        rightStyle = "0px";
                      }
  
                      
                        topStyle = `${rowIndex * 40}px`;
                      
                    } else if (seatIndex >= 12 && seatIndex <= 16) {
                        if (seatIndex<16){
                          topStyle = `${rowIndex * 40 + 120}px`;
                        } else if (seatIndex === 16) {
                          topStyle = `${rowIndex * 40 + 160}px`;
                        } else {
                          topStyle = `${rowIndex * 40 + 240}px`;
                        }

                      if (colIndex === 1 ) {
                        leftStyle = "45px";
                      } else if (colIndex === 2) {
                        leftStyle = "0px";
                      } else {
                        leftStyle =  "0px";
                      }
                      if(seatIndex === 15 || seatIndex === 16 ){
                        leftStyle = "0px";
                      }
                      if (colIndex === 2 || seatIndex === 15 || seatIndex === 16 ) {
                        rightStyle = "";
                      } else {
                        rightStyle = colIndex * 45 + "px";
                      }
  
                    } else if (seatIndex >=17 && seatIndex <= 34){
                      if (colIndex === 0 || colIndex === 1){
                        topStyle = `${rowIndex * 40 + 160}px`;
                      } else {
                        topStyle = `${rowIndex * 40 + 200}px`;
                      } 

                    if (colIndex === 0 ) {
                      leftStyle = "45px";
                    } else if (colIndex === 1) {
                      leftStyle = "0px";
                    } else {
                      leftStyle =  "0px";
                    }
                    if (colIndex === 2) {
                      rightStyle = "0px";
                    }else if (colIndex === 0) {
                        rightStyle = "45px";
                    } else {
                      //rightStyle = colIndex * 45 + "px";
                    }

                    } else if (seatIndex >=35 && seatIndex <=50){
                      if (colIndex === 2 && seatIndex !== 35) {
                        topStyle = `${rowIndex * 40 + 240}px`;
                      } else if (colIndex === 1 && seatIndex !== 49) {
                        topStyle = `${rowIndex * 40 + 240}px`;
                      } else if (seatIndex === 49) {
                        topStyle = `${rowIndex * 40 + 200}px`;
                      } else {
                        topStyle = `${rowIndex * 40 + 200}px`;
                      }
                    if ((colIndex === 1 && seatIndex !== 49)|| seatIndex === 48) {
                      leftStyle = "45px";
                    } else if (colIndex === 1) {
                      leftStyle = "0px";
                    } else {
                      leftStyle =  "0px";
                    }
                    if (colIndex === 1 && seatIndex !== 49) {
                      rightStyle = "0px";
                    }else if ((colIndex === 2 && seatIndex !== 35) ) {
                        rightStyle = "45px";
                    } else {
                      //rightStyle = colIndex * 45 + "px";
                    }

                    }
                    
                    return (
                      <div key={seatIndex}>
                        {isBooked === true ? (
                          <img
                            className="position-absolute chair"
                            style={{
                              top: topStyle,
                              left: leftStyle,
                              right: rightStyle,
                            }}
                            src={require("../../Assets/images/icon/red.png")}
                            alt=""
                          />
                        ) : isSelected ? (
                          <img
                            onClick={() => {
                              setSelectedSeats((prev) =>
                                prev.filter((seat) => seat !== seatIndex + 1)
                              );
                            }}
                            className="position-absolute chair"
                            style={{
                              cursor: "pointer",
                              top: topStyle,
                              left: leftStyle,
                              right: rightStyle,
                            }}
                            src={require("../../Assets/images/icon/green.png")}
                            alt=""
                          />
                        ) : (
                          <div
                            className="position-absolute w-custom"
                            onClick={() => {
                              if (canChoose) {
                                setSelectedSeats((prev) => [
                                  ...prev,
                                  seatIndex + 1,
                                ]);
                              }
                            }}
                            style={{
                              cursor: "pointer",
                              top: topStyle,
                              left: leftStyle,
                              right: rightStyle,
                            }}
                          >
                            <div className="position-relative">
                              <img
                                src={require("../../Assets/images/icon/empty.png")}
                                className="chair-img"
                                alt=""
                              />
                              <span
                                className="position-absolute chair-nums"
                                style={{
                                  top: "50%",
                                  left: "50%",
                                }}
                              > 
                                {seatIndex + 1}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
          :
            <div
            className="d-flex flex-wrap w-300 position-relative chairs-height"
            dir="rtl"
          >
            
            <img
              src={require("../../Assets/images/icon/toilet.png")}
              alt=""
              className="position-absolute chair"
              style={{ right: "0", top: `${5 * 40}px` }}
            />{" "}
            <img
              src={require("../../Assets/images/icon/toilet.png")}
              alt=""
              className="position-absolute chair"
              style={{ right: "45px", top: `${5 * 40}px` }}
            />
            <img
              src={require("../../Assets/images/icon/toilet.png")}
              alt=""
              className="position-absolute chair"
              style={{ right: "0", top: `${6 * 40}px` }}
            />{" "}
            <img
              src={require("../../Assets/images/icon/toilet.png")}
              alt=""
              className="position-absolute chair"
              style={{ right: "45px", top: `${6 * 40}px` }}
            />
            {Array.from({ length: 49 }, (_, seatIndex) => {
              const rowIndex = Math.floor(seatIndex / 4);
              const colIndex = seatIndex % 4;
              const isBooked = seats.includes(seatIndex + 1);
              const isSelected = selectedSeats.includes(seatIndex + 1);
              const imageIndex = rowIndex * 4 + colIndex;
              let leftStyle;
              if (seatIndex === 48) {
                leftStyle = "0";
              } else if (seatIndex === 46) {
                leftStyle = "50%";
              } else if (seatIndex === 47) {
                leftStyle = "45px";
              } else if (seatIndex === 20) {
                leftStyle = "45px";
              } else if (seatIndex === 21) {
                leftStyle = "0px";
              } else if (colIndex === 2) {
                leftStyle = "45px";
              } else if (colIndex === 3) {
                leftStyle = "0px";
              } else {
                leftStyle = colIndex * 45 + "px";
              }

              let rightStyle;
              if (colIndex === 2 || colIndex === 3) {
                rightStyle = "";
              } else if (seatIndex === 48) {
                rightStyle = "";
              } else if (seatIndex === 20 || seatIndex === 21) {
                rightStyle = "";
              } else {
                rightStyle = colIndex * 45 + "px";
              }

              let topStyle;
              if (seatIndex > 21 && seatIndex < 48) {
                topStyle = `${rowIndex * 40 + 40}px`;
              } else if (seatIndex === 48) {
                topStyle = `${rowIndex * 40}px`;
              } else {
                topStyle = `${rowIndex * 40}px`;
              }
              return (
                <div key={seatIndex}>
                  {isBooked === true ? (
                    <img
                      className="position-absolute chair"
                      style={{
                        top: topStyle,
                        left: leftStyle,
                        right: rightStyle,
                      }}
                      src={require("../../Assets/images/icon/red.png")}
                      alt=""
                    />
                  ) : isSelected ? (
                    <img
                      onClick={() => {
                        setSelectedSeats((prev) =>
                          prev.filter((seat) => seat !== seatIndex + 1)
                        );
                      }}
                      className="position-absolute chair"
                      style={{
                        cursor: "pointer",
                        top: topStyle,
                        left: leftStyle,
                        right: rightStyle,
                      }}
                      src={require("../../Assets/images/icon/green.png")}
                      alt=""
                    />
                  ) : (
                    <div
                      className="position-absolute w-custom"
                      onClick={() => {
                        if (canChoose) {
                          setSelectedSeats((prev) => [
                            ...prev,
                            seatIndex + 1,
                          ]);
                        }
                      }}
                      style={{
                        cursor: "pointer",
                        top: topStyle,
                        left: leftStyle,
                        right: rightStyle,
                      }}
                    >
                      <div className="position-relative">
                        <img
                          src={require("../../Assets/images/icon/empty.png")}
                          className="chair-img"
                          alt=""
                        />
                        <span
                          className="position-absolute chair-nums"
                          style={{
                            top: "50%",
                            left: "50%",
                          }}
                        >
                          {seatIndex + 1}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          }
              </div>
            </>
          )}
        </div>

        {/* Back Trip */}
        {localTrip?.backDate !== "no back date" && (
          <BackTrip
            selectedSeats={selectedBackSeats}
            setSelectedSeats={setSelectedBackSeats}
            setTripId={setTripId}
            maxCountParent={maxCount}
            maxBackCount={maxBackCount}
            setBackMaxCount={setBackMaxCount}
          />
        )}
        {loading ? (
          ""
        ) : (
          <Button
            onClick={HandleSendTrip}
            to="/payment"
            className="col-6 border-0 bg-gold text-decoration-none mx-auto"
          >
            <h5
              to="chairs"
              className={` text-center text-black rounded d-flex align-items-center justify-content-center gap-2 py-2 px-2 m-0 mb-lg-0 mb-2`}
              style={{ cursor: "pointer" }}
            >
              {CircleLoadingIcon && <CircleLoading />} اعتماد الحجز
            </h5>
          </Button>
        )}
      </div>

      <Footer />
    </>
  );
}
